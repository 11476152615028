import { configureStore } from "@reduxjs/toolkit";
import {
  alert,
  app,
  auth,
  brand,
  contact,
  order,
  products,
  promoCodes,
  promoCodesUsage,
  userReducer
} from "./reducers";

const store = configureStore({
  reducer: {
    auth,
    app,
    promoCodes,
    products,
    brand,
    order,
    contact,
    promoCodesUsage,
    userReducer,
    alert
  },

  devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
